import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { AgreementPage } from "../agreement/AgreementPage";
import { Layout } from "./Layout";
import { DirectLogin } from "../user/DirectLogin";
import { ForbiddenPage } from "./ForbiddenPage";
import { NotFoundPage } from "./NotFoundPage";
import { SameUser } from "../user/SameUser";
import { SignOut } from "../user/SignOut";
import { RedirectToLogin } from "./RedirectToLogin";
import { GardenerDocument } from "../gardener/gardener.model";
import { SignIn } from "../user/SignIn";
import { RegistrationOverviewPage } from "../registration/overview/RegistrationOverviewPage";
import { ProfilePage } from "../gardener/ProfilePage";
import { useOptionalUser } from "../user/UserProvider";
import { LinkProvider } from "../LinkProvider";
import { TaskProvider } from "../task/TaskProvider";
import { TaskRouter } from "../overview/overview/TaskRouter";
import { RedirectToAppointmentFollowUpTask } from "../overview/shared/RedirectToAppointmentFollowUpTask";
import { RedirectToRegistrationFollowUpTask } from "../overview/shared/RedirectToRegistrationFollowUpTask";
import { RedirectToNewTask } from "../overview/shared/RedirectToNewTask";
import { RedirectToTask } from "../overview/shared/RedirectToTask";
import { IntegratedCustomerDetailPage } from "../customer/IntegratedCustomerDetailPage";
import { IntegratedCustomerOverviewPage } from "../customer/customer-overview/IntegratedCustomerOverviewPage";
import { IntegratedOverviewPage } from "../overview/overview/IntegratedOverviewPage";
import { GlobalBlockingTutorialRouter } from "../guides/GlobalBlockingTutorialRouter";
import { IntegratedGuidesPage } from "../guides/IntegratedGuidesPage";
import { RedirectToNewTaskTask } from "../overview/shared/RedirectToNewTaskTask";

export const Application = () => {
    const gardener = useOptionalUser();

    return (
        <BrowserRouter>

            {gardener ? (
                <TaskProvider userId={gardener.id}>

                    <LinkProvider>

                        <GlobalBlockingTutorialRouter>
                            <ProtectedRoutes gardener={gardener} />
                        </GlobalBlockingTutorialRouter>

                    </LinkProvider>

                </TaskProvider>
            ) : (
                <UnprotectedRoutes />
            )}

        </BrowserRouter>
    );
};

const ProtectedRoutes = (props: { gardener: GardenerDocument }) => {
    const { gardener } = props;

    const isGardener = gardener.status === "active" || gardener.status === "terminated";

    return (
        <Switch>

            <Route exact path="/">
                {(() => {
                    if ( isGardener ) return <Redirect to="/overview" />;
                    if ( gardener.status === "pending" || gardener.status === "banned" ) return <Redirect to="/profile" />;
                    if ( gardener.status === "applicant" ) return <Redirect to="/agreements/create" />;

                    return (
                        <Layout>
                            <NotFoundPage />
                        </Layout>
                    );
                })()}
            </Route>

            <Route exact path={["/overview", "/overview/:tab"]}>
                {isGardener ? (
                    <Layout>
                        <IntegratedOverviewPage />
                    </Layout>
                ) : (
                    <Layout>
                        <ForbiddenPage />
                    </Layout>
                )}
            </Route>

            <Route exact path="/overview/:tab/:taskId">
                {isGardener ? (
                    <TaskRouter />
                ) : (
                    <Layout>
                        <ForbiddenPage />
                    </Layout>
                )}
            </Route>

            <Route exact path="/overview/redirects/new-task/:gardeningTaskId">
                {isGardener ? (
                    <Layout>
                        <RedirectToNewTaskTask />
                    </Layout>
                ) : (
                    <Layout>
                        <ForbiddenPage />
                    </Layout>
                )}
            </Route>

            <Route exact path="/overview/redirects/appointment-task/:customerId">
                {isGardener ? (
                    <Layout>
                        <RedirectToAppointmentFollowUpTask />
                    </Layout>
                ) : (
                    <Layout>
                        <ForbiddenPage />
                    </Layout>
                )}
            </Route>

            <Route exact path="/overview/redirects/registration-task/:taskId">
                {isGardener ? (
                    <Layout>
                        <RedirectToRegistrationFollowUpTask />
                    </Layout>
                ) : (
                    <Layout>
                        <ForbiddenPage />
                    </Layout>
                )}
            </Route>

            <Route exact path="/registrations">
                {isGardener ? (
                    <Layout>
                        <RegistrationOverviewPage gardener={gardener} />
                    </Layout>
                ) : (
                    <Layout>
                        <ForbiddenPage />
                    </Layout>
                )}
            </Route>

            <Route exact path={["/customers", "/customers/:tab"]}>
                {isGardener ? (
                    <Layout>
                        <IntegratedCustomerOverviewPage />
                    </Layout>
                ) : (
                    <Layout>
                        <ForbiddenPage />
                    </Layout>
                )}
            </Route>

            <Route exact path="/customers/:tab/:matchId">
                {isGardener ? (
                    <Layout>
                        <IntegratedCustomerDetailPage />
                    </Layout>
                ) : (
                    <Layout>
                        <ForbiddenPage />
                    </Layout>
                )}
            </Route>

            <Route exact path="/agreements/create">
                <Layout>
                    <AgreementPage gardener={gardener} />
                </Layout>
            </Route>

            <Route exact path="/profile">
                <Layout>
                    <ProfilePage gardener={gardener} />
                </Layout>
            </Route>

            <Route exact path="/guides">
                {isGardener ? (
                    <Layout>
                        <IntegratedGuidesPage />
                    </Layout>
                ) : (
                    <Layout>
                        <ForbiddenPage />
                    </Layout>
                )}
            </Route>

            <Route exact path="/gardeners/:gardenerId">
                <SameUser userId={gardener.id}>
                    <Redirect to="/" />
                </SameUser>
            </Route>

            <Route exact path="/a/:gardenerId">
                <SameUser userId={gardener.id}>
                    <Redirect to="/agreements/create" />
                </SameUser>
            </Route>

            <Route exact path="/gardeners/:gardenerId/agreement">
                <SameUser userId={gardener.id}>
                    <Redirect to="/agreements/create" />
                </SameUser>
            </Route>

            <Route exact path="/x/:matchId">
                {isGardener ? (
                    <Layout>
                        <RedirectToNewTask />
                    </Layout>
                ) : (
                    <Layout>
                        <ForbiddenPage />
                    </Layout>
                )}
            </Route>

            <Route exact path="/t/:taskId">
                {isGardener ? (
                    <Layout>
                        <RedirectToTask />
                    </Layout>
                ) : (
                    <Layout>
                        <ForbiddenPage />
                    </Layout>
                )}
            </Route>

            <Route exact path="/login">
                <SignIn />
            </Route>

            <Route exact path="/logout">
                <SignOut />
            </Route>

            <Route path="/">
                <Layout>
                    <NotFoundPage />
                </Layout>
            </Route>

        </Switch>
    );
};

const UnprotectedRoutes = () => {
    return (
        <Switch>

            <Route exact path="/gardeners/:gardenerId">
                <DirectLogin />
            </Route>

            <Route exact path="/gardeners/:gardenerId/registrations">
                <DirectLogin />
            </Route>

            <Route exact path="/a/:gardenerId">
                <DirectLogin />
            </Route>

            <Route exact path="/gardeners/:gardenerId/agreement">
                <DirectLogin />
            </Route>

            <Route exact path="/login">
                <SignIn />
            </Route>

            <Route exact path="/logout">
                <RedirectToLogin fresh />
            </Route>

            <Route path="/">
                <RedirectToLogin />
            </Route>

        </Switch>
    );
};
