import moment from "moment";

export type Validation = {
    severity: "error" | "warning" | "success";
    message: string;
} | undefined;

export const validateMandatory = (value: any) => {
    if ( !value ) return "Skal udfyldes";

    return undefined;
};

export const validateMandatoryValidation = (value: any): Validation => {
    if ( !value ) return { severity: "error", message: "Skal udfyldes" };

    return undefined;
};

export const validateCvr = (value: string) => {
    if ( !value ) return undefined;

    if ( !value.match(/^\d{8}$/g) ) return "Skal være på formen \"12345678\"";

    return undefined;
};

export const validateEmail = (value: string) => {
    if ( !value ) return undefined;

    if ( !value.match(/\S+@\S+\.\S+/) ) return "Skal være en gyldig email";

    return undefined;
};

export const validatePhoneNumber = (value: string) => {
    if ( !value ) return undefined;

    if ( !value.match(/(.*\d.*){8}/) ) return "Skal være minimum 8 tal";

    return undefined;
};

export const validateDate = (value: string) => {
    if ( !moment(value, moment.ISO_8601, true).isValid() ) return "Skal være på formen \"24-12-2020\"";

    return undefined;
}

export const validateDateNotInTheFuture = (value: string, now: string) => {
    if ( !value ) return undefined;
    if ( validateDate(value) ) return undefined;

    if ( moment(now).startOf("day").isBefore(value) ) return "Skal senest være i dag";

    return undefined;
}

export const validateDateNotBefore = (value: string, date: string) => {
    if ( !value ) return undefined;
    if ( validateDate(value) ) return undefined;

    if ( moment(value).isBefore(date, "day") ) return `Skal tidligst være ${moment(date).format("DD-MM-YYYY")}`;

    return undefined;
}

export const validateDateNotAfter = (value: string, date: string) => {
    if ( !value ) return undefined;
    if ( validateDate(value) ) return undefined;

    if ( moment(value).isAfter(date, "day") ) return `Skal senest være ${moment(date).format("DD-MM-YYYY")}`;

    return undefined;
}
