import { useCallback, useMemo } from "react";

import { Dialog, DialogActions, DialogContent, DialogHeader, useDialog } from "../../../dialog";
import { FormItem } from "../../framework/core/form-item.model";
import { FormProps, FormProvider, useForm } from "../../framework/react/FormProvider";
import { NeutralAction, PositiveAction } from "../../../card";
import { FormItemComponent } from "./FormItemComponent";
import { FormController } from "../../framework/core/form.controller";
import { DatePickerFormItemSupport } from "../form-item/DatePickerFormItemComponent";
import { DropdownFormItemSupport } from "../form-item/DropdownFormItemComponent";
import { GroupFormItem, GroupFormItemSupport } from "../form-item/GroupFormItemComponent";
import { InfoFormItemSupport } from "../form-item/InfoFormItemComponent";
import { MultiChoiceFormItemSupport } from "../form-item/MultiChoiceFormItemComponent";
import { PageFormItem, PageFormItemSupport } from "../form-item/PageFormItemComponent";
import { SingleChoiceFormItemSupport } from "../form-item/SingleChoiceFormItemComponent";
import { TextAreaFormItemSupport } from "../form-item/TextAreaFormItemComponent";
import { HorizontalButtonsFormItemSupport } from "../form-item/HorizontalButtonsFormItemComponent";
import { NoticeFormItemSupport } from "../form-item/NoticeFormItemComponent";
import { RegistrationFormItemSupport } from "../form-item/RegistrationFormItemComponent";
import { VerticalButtonsFormItemSupport } from "../form-item/VerticalButtonsFormItemComponent";

export const FormDialog = () => {
    const { page, hasPreviousPage, hasNextPage, goBack, goForward } = useForm();

    const defaultBackText = !hasPreviousPage ? "Annuller" : "Tilbage";
    const backText = page.backText ?? defaultBackText;

    const defaultNextText = hasNextPage ? "Næste" : "Bekræft";
    const nextText = page.nextText ?? defaultNextText;

    return (
        <Dialog>
            <DialogHeader>{page.title}</DialogHeader>

            <DialogContent>
                <FormItemComponent item={page.item} />
            </DialogContent>

            <DialogActions>
                <NeutralAction onClick={goBack}>{backText}</NeutralAction>
                <PositiveAction onClick={goForward}>{nextText}</PositiveAction>
            </DialogActions>

        </Dialog>
    );
};

export const useFormDialog = <T extends PageFormItem<FormItem> | GroupFormItem<PageFormItem<FormItem>[]>>(item: T, onConfirm: FormProps<T>["onSubmit"]) => {
    const { openDialog, closeDialog } = useDialog();

    const formController = useMemo(() => {
        return new FormController([
            new PageFormItemSupport(),
            new GroupFormItemSupport(),
            new SingleChoiceFormItemSupport(),
            new MultiChoiceFormItemSupport(),
            new InfoFormItemSupport(),
            new NoticeFormItemSupport(),
            new TextAreaFormItemSupport(),
            new DatePickerFormItemSupport(),
            new DropdownFormItemSupport(),
            new HorizontalButtonsFormItemSupport(),
            new VerticalButtonsFormItemSupport(),
            new RegistrationFormItemSupport(),
        ]);
    }, []);

    const confirm: FormProps<T>["onSubmit"] = useCallback((controller, item) => {
        closeDialog();

        onConfirm(controller, item);
    }, [closeDialog, onConfirm]);

    return useCallback(() => {
        if ( !item ) return;

        openDialog(
            <FormProvider controller={formController} item={item} onSubmit={confirm} onCancel={closeDialog}>
                <FormDialog />
            </FormProvider>
        );
    }, [openDialog, closeDialog, confirm, formController, item]);
};
